
<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full form-col-2 pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >allocation addenda
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >natural gas allocation addendum</label
          >
          <div class="form-row">
            <div class="text-title">late charge</div>
            <TextInputSmall name="naturalGasLateCharge" />
            <div class="text-title">(not to exceed $3)</div>
          </div>
          <div class="form-row text-title">
            we will allocate the monthly gas bill based on the allocation method
            checked below:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="gasBillBasedOn.sqFootage"
                />
              </button>

              <div class="text-title mr-8">sq. footage</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="gasBillBasedOn.peopleInUnit"
                />
              </button>
              <div class="text-title mr-8">people in unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="gasBillBasedOn.sqFootageAndPeopleInUnit"
                />
              </button>
              <div class="text-title mr-8">sq. footage and poeple in unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="gasBillBasedOn.perDwellingUnit"
                />
              </button>
              <div class="text-title mr-8">per dwelling unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="gasBillBasedOn.other"
                />
              </button>
              <div class="text-title mr-8">other formula</div>
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">
              before the bill is allocated, a deduction of
            </div>
            <TextInputSmall name="deductionInCommonAreas" />
            <div class="text-title">
              % will be made to cover consumption in common areas
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">an addministrative fee of</div>
            <TextInputSmall name="naturalGasAdminFee" />
            <div class="text-title">
              per month (not to exceed $3) will be added to your bill
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >services/government fees allocation addendum</label
          >
          <div class="form-row text-title">
            we will allocate the following services and government fees:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="serviceAndGovFees.cableSatellite"
              />
              <div class="text-title mr-8">cable/satellite television</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="serviceAndGovFees.stormwaterDrainage"
              />
              <div class="text-title mr-8">stormwater/drainage</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="serviceAndGovFees.trashRecycling"
              />
              <div class="text-title mr-8">trash removal/recycling</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="serviceAndGovFees.streetRepairFee"
              />
              <div class="text-title mr-8">street repair/maintenance fee</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="serviceAndGovFees.emergencyFee"
              />
              <div class="text-title mr-8">emergency services fee</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="serviceAndGovFees.conservationFee"
              />
              <div class="text-title mr-8">conservation district fee</div>
            </div>
            <div class="form-row">
              <CheckboxInputSmall
                type="checkbox"
                name="serviceAndGovFees.inspectionFee"
              />
              <div class="text-title mr-8">inspection fee</div>
            </div>
            <div class="form-row" style="margin-bottom: 1rem">
              <CheckboxInputSmall
                type="checkbox"
                name="serviceAndGovFees.registrationFee"
              />
              <div class="text-title mr-8">registration/license fee</div>
            </div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall
              type="checkbox"
              name="other1Checkbox"
              style="margin-left: 0"
            />
            <div class="text-title">other</div>

            <TextField name="other1" class="ml-4" />
            <CheckboxInputSmall
              type="checkbox"
              name="other2Checkbox"
              style="margin-left: 0"
            />
            <div class="text-title">other</div>

            <TextField name="other2" class="ml-4" />
          </div>
          <div class="form-row">
            <CheckboxInputSmall
              type="checkbox"
              name="other3Checkbox"
              style="margin-left: 0"
            />
            <div class="text-title">other</div>

            <TextField name="other3" class="ml-4" />
            <CheckboxInputSmall
              type="checkbox"
              name="other4Checkbox"
              style="margin-left: 0"
            />
            <div class="text-title">other</div>

            <TextField name="other4" class="ml-4" />
          </div>
          <div class="form-row">
            <CheckboxInputSmall
              type="checkbox"
              name="other5Checkbox"
              style="margin-left: 0"
            />
            <div class="text-title">other</div>

            <TextField name="other5" class="ml-4" />
            <CheckboxInputSmall
              type="checkbox"
              name="other6Checkbox"
              style="margin-left: 0"
            />
            <div class="text-title">other</div>

            <TextField name="other6" class="ml-4" />
          </div>
          <div class="form-row">
            <div class="text-title">late charge</div>
            <TextInputSmall name="servicesLateCharge" />
            <div class="text-title">(not to exceed $3)</div>
          </div>
          <div class="form-row text-title">
            we will allocate the indicated services and government fees based on
            the allocation method checked below:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="serviceFeesBasedOn.sqFootage"
                />
              </button>

              <div class="text-title mr-8">sq. footage</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="serviceFeesBasedOn.peopleInUnit"
                />
              </button>
              <div class="text-title mr-8">people in unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="serviceFeesBasedOn.sqFootageAndPeopleInUnit"
                />
              </button>
              <div class="text-title mr-8">sq. footage and poeple in unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="serviceFeesBasedOn.perDwellingUnit"
                />
              </button>
              <div class="text-title mr-8">per dwelling unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="serviceFeesBasedOn.other"
                />
              </button>
              <div class="text-title mr-8">other formula</div>
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">an addministrative fee of</div>
            <TextInputSmall name="servicesAdminfee" />
            <div class="text-title">
              per month (not to exceed $3) will be added to your bill
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >stormwater/drainage allocation addendum</label
          >
          <div class="form-row text-title">
            we will allocate the monthly stormwater/drainage bill based on the
            allocation method checked below:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="stormwaterBasedOn.sqFootage"
                />
              </button>

              <div class="text-title mr-8">sq. footage</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="stormwaterBasedOn.peopleInUnit"
                />
              </button>
              <div class="text-title mr-8">people in unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="stormwaterBasedOn.sqFootageAndPeopleInUnit"
                />
              </button>
              <div class="text-title mr-8">sq. footage and poeple in unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="stormwaterBasedOn.perDwellingUnit"
                />
              </button>
              <div class="text-title mr-8">per dwelling unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="stormwaterBasedOn.other"
                />
              </button>
              <div class="text-title mr-8">other formula</div>
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">an addministrative fee of</div>
            <TextInputSmall name="stormwaterAdminFee" type="number" />
            <div class="text-title">
              per month (not to exceed $3) will be added to your bill
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >trash removal/recycling allocation addendum</label
          >
          <div class="form-row">
            <div class="text-title">late charge</div>
            <TextInputSmall name="trashLateCharge" type="number" />
            <div class="text-title">(not to exceed $3)</div>
          </div>
          <div class="form-row text-title">
            we will allocate the monthly trash removal/recycling bill based on
            the allocation method checked below:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="trashBasedOn.sqFootage"
                />
              </button>

              <div class="text-title mr-8">sq. footage</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="trashBasedOn.peopleInUnit"
                />
              </button>
              <div class="text-title mr-8">people in unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="trashBasedOn.sqFootageAndPeopleInUnit"
                />
              </button>
              <div class="text-title mr-8">sq. footage and poeple in unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="trashBasedOn.perDwellingUnit"
                />
              </button>
              <div class="text-title mr-8">per dwelling unit</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall type="checkbox" name="trashBasedOn.other" />
              </button>
              <div class="text-title mr-8">other formula</div>
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">an addministrative fee of</div>
            <TextInputSmall name="trashAdminFee" type="number" />
            <div class="text-title">
              per month (not to exceed $3) will be added to your bill
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >central system unit allocation addendum</label
          >
          <div class="form-row text-title">
            we will allocate the utilities used in operating the central
            system(s) based on the allocation method checked below:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="utilitiesBasedOn.sqFootage"
                />
              </button>

              <div class="text-title mr-8">sq. footage</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="utilitiesBasedOn.submeteredElectricityOrWater"
                />
              </button>

              <div class="text-title mr-8">submetered electricity or water</div>
            </div>
            <div class="form-row" style="margin-bottom: 1rem">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="utilitiesBasedOn.both"
                />
              </button>

              <div class="text-title mr-8">a combination of methods</div>
            </div>
          </div>
          <div class="form-row text-title">
            we will send a separate bill to cover a share of the cost of
            electricity and gas used in operating the following:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="billForCentralHeating"
                />
              </button>

              <div class="text-title mr-8">
                central heating and air conditioning system
              </div>
            </div>
            <div class="form-row" style="margin-bottom: 1rem">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="billForCentralHotWater"
                />
              </button>

              <div class="text-title mr-8">central hot water system</div>
            </div>
          </div>
          <div class="form-row text-title">
            the formula for allocating utility costs for the central system(s)
            will be based on the method checked below:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="formulaForUtilities.sqFootageCheckbox"
                />
              </button>

              <div class="text-title">sq. footage. the percentage is</div>
              <div>
                <TextInputSmall
                  name="formulaForUtilities.sqFootagePercent"
                  type="number"
                />
              </div>
            </div>
            <div class="form-row flex-wrap">
              <div class="form-row">
                <button>
                  <CheckboxInputSmall
                    type="checkbox"
                    name="formulaForUtilities.submeteredUtility"
                  />
                </button>

                <div class="text-title mr-2">submemtered utility:</div>
              </div>
              <div class="form-row">
                <div class="text-title">
                  the submetered utiliy being used is
                </div>
              </div>
              <div class="form-row">
                <CheckboxInputSmall
                  type="checkbox"
                  name="formulaForUtilities.submeteredUtilityWater"
                />
                <div class="text-title mr-4">water</div>
              </div>
              <div class="form-row"><div class="text-title">or</div></div>
              <div class="form-row" style="margin-bottom: 1rem">
                <CheckboxInputSmall
                  type="checkbox"
                  name="formulaForUtilities.submeteredUtilityElectricity"
                />
                <div class="text-title mr-8">electricity</div>
              </div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="formulaForUtilities.bothCheckbox"
                />
              </button>

              <div class="text-title mr-2">
                a combination of the first two formulas:
              </div>
            </div>
            <div class="form-row flex-wrap ml-12">
              <div class="form-row">
                <div class="text-title mr-2">
                  the utility being used for the "submetered utility" portion of
                  the formula is
                </div>
              </div>
              <div class="form-row">
                <CheckboxInputSmall
                  type="checkbox"
                  name="formulaForUtilities.bothSubmeteredUtilityWater"
                />
                <div class="text-title mr-2">submetered water</div>
              </div>
              <div class="form-row text-title">or</div>
              <div class="form-row">
                <CheckboxInputSmall
                  type="checkbox"
                  name="formulaForUtilities.bothSubmeteredUtilityElectricity"
                />
                <div class="text-title mr-2">submetered electricity.</div>
              </div>
              <div class="form-row" style="margin-bottom: 1rem">
                <div class="text-title">the percentage is</div>
                <div>
                  <TextInputSmall
                    name="formulaForUtilities.bothPercent"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-row text-title">
            the average monthly amount of the allocated share of central system
            utilities for the unit for the most recent calendar year was:
          </div>
          <div class="form-row">
            <TextInputSmall name="monthlyAmountCentralHVAC" type="number" />
            <div class="text-title">for the central HVAC system</div>
          </div>
          <div class="form-row">
            <TextInputSmall name="monthlyAmountCentralHotWater" type="number" />
            <div class="text-title">for the central hot water system</div>
          </div>
          <div class="form-row text-title ml-4">
            the above amounts represent an allocation based on:
          </div>
          <div class="form-row flex-wrap ml-8">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="centralSystemUtils.sqFootage"
                />
              </button>

              <div class="text-title mr-8">sq. footage</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="centralSystemUtils.submeteredUtility"
                />
              </button>

              <div class="text-title mr-8">submetered utility</div>
            </div>
            <div class="form-row" style="margin-bottom: 1rem">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="centralSystemUtils.both"
                />
              </button>

              <div class="text-title mr-8">a combination of methods</div>
            </div>
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextField from "@/components/form/TextField";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import TextInputSmall from "@/components/form/TextInputSmall";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import { FinalForm } from "vue-final-form";

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    ModalFooter,
    TextField,
    CheckboxInputSmall,
    TextInputSmall,
    FinalForm,
  },
  computed: {
    initialValues() {
      return {
        naturalGasLateCharge: "",
        gasBillBasedOn: {
          sqFootage: "",
          peopleInUnit: "",
          sqFootageAndPeopleInUnit: "",
          perDwellingUnit: "",
          other: "",
        },
        deductionInCommonAreas: "",
        naturalGasAdminFee: "",
        serviceAndGovFees: {
          cableSatellite: "",
          stormwaterDrainage: "",
          trashRecycling: "",
          streetRepairFee: "",
          emergencyFee: "",
          conservationFee: "",
          inspectionFee: "",
          registrationFee: "",
        },
        other1Checkbox: "",
        other1: "",
        other2Checkbox: "",
        other2: "",
        other3Checkbox: "",
        other3: "",
        other4Checkbox: "",
        other4: "",
        other5Checkbox: "",
        other5: "",
        other6Checkbox: "",
        other6: "",
        servicesLateCharge: "",
        serviceFeesBasedOn: {
          sqFootage: "",
          peopleInUnit: "",
          sqFootageAndPeopleInUnit: "",
          perDwellingUnit: "",
          other: "",
        },
        servicesAdminfee: "",
        stormwaterBasedOn: {
          sqFootage: "",
          peopleInUnit: "",
          sqFootageAndPeopleInUnit: "",
          perDwellingUnit: "",
          other: "",
        },
        stormwaterAdminFee: "",
        trashLateCharge: "",
        trashBasedOn: {
          sqFootage: "",
          peopleInUnit: "",
          sqFootageAndPeopleInUnit: "",
          perDwellingUnit: "",
          other: "",
        },
        trashAdminFee: "",
        utilitiesBasedOn: {
          sqFootage: "",
          submeteredElectricityOrWater: "",
          both: "",
        },
        billForCentralHeating: "",
        billForCentralHotWater: "",
        formulaForUtilities: {
          sqFootageCheckbox: "",
          sqFootagePercent: "",
          submeteredUtility: "",
          submeteredUtilityWater: "",
          submeteredUtilityElectricity: "",
          bothCheckbox: "",
          bothSubmeteredUtilityWater: "",
          bothSubmeteredUtilityElectricity: "",
          bothPercent: "",
        },
        monthlyAmountCentralHVAC: "",
        monthlyAmountCentralHotWater: "",
        centralSystemUtils: {
          sqFootage: "",
          submeteredUtility: "",
          both: "",
        },
      };
    },
  },
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },
      naturalGasLateCharge: "",
      gasBillBasedOn: {
        sqFootage: "",
        peopleInUnit: "",
        sqFootageAndPeopleInUnit: "",
        perDwellingUnit: "",
        other: "",
      },
      deductionInCommonAreas: "",
      naturalGasAdminFee: "",
      serviceAndGovFees: {
        cableSatellite: "",
        stormwaterDrainage: "",
        trashRecycling: "",
        streetRepairFee: "",
        emergencyFee: "",
        conservationFee: "",
        inspectionFee: "",
        registrationFee: "",
      },
      other1Checkbox: "",
      other1: "",
      other2Checkbox: "",
      other2: "",
      other3Checkbox: "",
      other3: "",
      other4Checkbox: "",
      other4: "",
      other5Checkbox: "",
      other5: "",
      other6Checkbox: "",
      other6: "",
      servicesLateCharge: "",
      serviceFeesBasedOn: {
        sqFootage: "",
        peopleInUnit: "",
        sqFootageAndPeopleInUnit: "",
        perDwellingUnit: "",
        other: "",
      },
      servicesAdminfee: "",
      stormwaterBasedOn: {
        sqFootage: "",
        peopleInUnit: "",
        sqFootageAndPeopleInUnit: "",
        perDwellingUnit: "",
        other: "",
      },
      stormwaterAdminFee: "",
      trashLateCharge: "",
      trashBasedOn: {
        sqFootage: "",
        peopleInUnit: "",
        sqFootageAndPeopleInUnit: "",
        perDwellingUnit: "",
        other: "",
      },
      trashAdminFee: "",
      utilitiesBasedOn: {
        sqFootage: "",
        submeteredElectricityOrWater: "",
        both: "",
      },
      billForCentralHeating: "",
      billForCentralHotWater: "",
      formulaForUtilities: {
        sqFootageCheckbox: "",
        sqFootagePercent: "",
        submeteredUtility: "",
        submeteredUtilityWater: "",
        submeteredUtilityElectricity: "",
        bothCheckbox: "",
        bothSubmeteredUtilityWater: "",
        bothSubmeteredUtilityElectricity: "",
        bothPercent: "",
      },
      monthlyAmountCentralHVAC: "",
      monthlyAmountCentralHotWater: "",
      centralSystemUtils: {
        sqFootage: "",
        submeteredUtility: "",
        both: "",
      },
    };
  },
  methods: {
    saveData() {},
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}
.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
